import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import Wrapper from "../timvir/wrapper";
const MDXLayout = Wrapper;
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h1: "h1",
    h2: "h2",
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      children: "Timvir"
    }), "\n", _jsx(_components.h2, {
      children: "What is Timvir?"
    }), "\n", _jsxs(_components.p, {
      children: ["Timvir is a project documentation tool, currently optimized for React + Next.js projects (though nothing ties it inherently to Next.js). In its core, Timvir is a set of React\n", _jsx(_components.strong, {
        children: "components"
      }), " and ", _jsx(_components.strong, {
        children: "conventions"
      }), " which aim to make writing documentation easier."]
    }), "\n", _jsxs(_components.p, {
      children: ["Timvir grew out of our realization that we want the documentation to be part of the product (website, web app), not a separate artefact.\nAs such, Timvir is meant to be integrated into, and deployed as part of your product. For example, in a Next.js app it means putting the\ndocumentation into ", _jsx(_components.code, {
        children: "pages/docs/**/*.mdx"
      }), ", and making it available under ", _jsx(_components.code, {
        children: "https://example.com/docs"
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: ["The ", _jsx(_components.code, {
        children: "timvir/blocks"
      }), " package provides standard components for documenting colors, typography, react components etc. This set of components\nwill be expanded in the future to cover the needs when documenting a project and its design system."]
    }), "\n", _jsxs(_components.p, {
      children: ["A second part of Timvir is a set of ", _jsx(_components.strong, {
        children: "conventions"
      }), " how to write and document React components. It is not necessary to follow these conventions\nwhen using Timvir, though they are used internally in the Timvir codebase and certain tooling around Timvir relies heavily on those.\nThese conventions are a living standard, subject to change as we discover ways how to refine them."]
    }), "\n", _jsxs(_components.p, {
      children: ["The third part of Timvir is a commandline tool (", _jsx(_components.code, {
        children: "@timvir/cli"
      }), ") which aims to simplify certain workflows in a React project."]
    }), "\n", _jsx(_components.h2, {
      children: "Stage"
    }), "\n", _jsx(_components.p, {
      children: "The current focus is on:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Integrate timvir into a few projects to find out whether the conventions makes sense."
      }), "\n", _jsx(_components.li, {
        children: "Improve the rough edges in the commandline tool, make common operations easy to execute."
      }), "\n", _jsx(_components.li, {
        children: "Expand the set of components based on the users needs when writing documentation pages."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      children: "Prior art"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: _jsx(_components.a, {
            href: "https://catalog.style",
            children: "Catalog"
          })
        }), ": Based on plain Markdown, with custom blocks (called specimen)."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: _jsx(_components.a, {
            href: "https://docup.now.sh/",
            children: "Docup"
          })
        }), ": Similar to Catalog, based on Markdown, limited set of custom blocks."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: _jsx(_components.a, {
            href: "https://www.docz.site/",
            children: "Docz"
          })
        }), ": Uses Gatsby + MDX, limited set of custom blocks."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: _jsx(_components.a, {
            href: "https://docusaurus.io/",
            children: "Docusaurus"
          })
        }), ": Uses MDX"]
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  return _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  });
}
